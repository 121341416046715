<template>
  <FormContent :selected='selected' class='pa-6'>
    <div class='margin-fix pa-3'>
       <ComboboxInput label='Banco *' placeHolder='Informe o banco'
        v-model='form.bankAccount.bankCode'
        :options='bankCodeOptions'
        labelValue='text'
        itemValue='value'
        @focusout="validate('bankCode')"
        :validationRules='[validationRules.bankCode]'
        labelWidth="20%" />
    </div>
    <div class='margin-fix pa-3'>
      <TextFieldInput label='Agência *' placeHolder='Informe o número da agência'
        v-model='form.bankAccount.agency'
        @focusout="validate('agency')"
        :validationRules='[validationRules.agency]'
        labelWidth="20%" />
    </div>
    <div class='margin-fix pa-3'>
      <TextFieldInput label='Conta Corrente *' placeHolder='Informe o número da conta'
        v-model='form.bankAccount.number'
        @focusout="validate('number')"
        :validationRules='[validationRules.number]'
        labelWidth="20%" />
    </div>
  </FormContent>
</template>

<script>

import Vue from 'vue';
import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import ComboboxInput from '@/components/template/form/input/ComboboxInput';
import { BankCodeOptions } from '../../../static-data/BankCodeOptions';

export default Vue.extend({
  name: 'BankDataForm',
  components: {
    FormContent,
    TextFieldInput,
    ComboboxInput,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Dados Bancários',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selected: false,
      bankCode: null,
      bankCodeOptions: BankCodeOptions,
    };
  },
  methods: {
    validate(field) {
      this.$emit('validateRequired', this.$options.name, field, this.form.bankAccount[field]);
    },
  },
});

</script>
