<template>
  <div class="form-content form-content--no-space flex-column">
      <ComboboxInput label='Área na empresa*'
      placeHolder='Informe a área, como Comercial, Financeira, SAC, TI, Jurídica...'
        v-model='form.area'
        labelValue='text'
        itemValue='value'
        :options='areaOptions'
        labelWidth="20%"
        :validationRules="[Rules.companyAreaFullyFilled]"/>
      <div class="d-flex">
        <label class="custom-label--20">E-mail *</label>
        <v-text-field v-model="form.email" placeholder="Informe o correio eletrônico" :rules="[Rules.emailFullyFilled]" filled light dense />
      </div>
      <div class="d-flex">
        <label class="custom-label--20">Telefone *</label>
        <MaskInput
          dense
          light
          filled
          v-model="form.phone"
          :options="PhoneMaskOptions"
          placeholder='Informe o código de área e telefone'
          :rules="[Rules.phoneNumberFullyFilled]"
        />
      </div>
  </div>
</template>

<script>
import ComboboxInput from '@/components/template/form/input/ComboboxInput';
import { AreaOptions } from '../../../static-data/AreaOptions';
import MaskInput from '@/components/template/form/input/MaskInput';
import utils from '@/utils/utils';

const PhoneMaskOptions = {
  numericOnly: true,
  delimiters: ['(', ') ', ' ', '-'],
  blocks: [0, 2, 5, 4],
};

export default {
  name: 'AContactDataForm',
  components: {
    ComboboxInput,
    MaskInput,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Cadastro de Contato',
    },
    form: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    areaOptions: AreaOptions,
  }),
  computed: {
    PhoneMaskOptions() {
      return PhoneMaskOptions;
    },
    Rules() {
      return {
        companyAreaFullyFilled: (value) => {
          if (!value) return 'Campo Obrigatório';
          return true;
        },
        emailFullyFilled: (value) => (utils.isEmailValid(value) ? true : 'Por favor, informe um e-mail válido.'),
        phoneNumberFullyFilled: (value) => {
          if (!value) return 'Campo Obrigatório';
          const numberValue = value.replace(/\D/g, '');
          if (numberValue.length < 10) {
            return 'Preencha o telefone corretamente. Ex: (99) 99999 9999 ou (99) 99999 999';
          }

          return true;
        },
      };
    },
  },
};

</script>
