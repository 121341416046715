<template>
  <FormContent :selected='selected' class='pa-6'>
    <div class='margin-fix pa-3'>
      <TextFieldInput label='Razão Social*' placeHolder='Informe a razão social'
        v-model='form.companyName'
        :validationRules='[validationRules.companyName]'
        @focusout="validate('companyName')"
        labelWidth="20%" />
    </div>
    <div class='margin-fix pa-3'>
      <TextFieldInput label='Nome fantasia*' placeHolder='Informe o nome fantasia'
        v-model='form.tradingName'
        :validationRules='[validationRules.tradingName]'
        @focusout="validate('tradingName')"
        labelWidth="20%" />
    </div>
    <div class='margin-fix pa-3'>
      <TextFieldInput label='Prefixo*' placeHolder='Informe o prefixo'
        v-model='form.marketplaceIdPrefix'
        :validationRules='[validationRules.marketplaceIdPrefix]'
        @focusout="validate('marketplaceIdPrefix')"
        labelWidth="20%"/>
    </div>
    <div class='margin-fix pa-3'>
      <TextFieldInput label='Marketplace Slug*' placeHolder='Informe o Marketplace Slug'
        v-model='form.marketplaceSlug'
        :validationRules='[validationRules.marketplaceSlug]'
        @focusout="validate('marketplaceSlug')"
        labelWidth="20%"/>
    </div>
    <div class='margin-fix pa-3'>
      <TextFieldInput label='CNPJ*' placeHolder='Informe o CNPJ'
        v-model='form.cnpj'
        :validationRules='[validationRules.cnpj]'
        @focusout="validate('cnpj')"
        labelWidth="20%"/>
    </div>
    <div class='margin-fix pa-3'>
      <TextFieldInput label='Ramo de atividade*' placeHolder='Informe o ramo de atividade'
        v-model='form.activity'
        :validationRules='[validationRules.activity]'
        @focusout="validate('activity')"
        labelWidth="20%"/>
    </div>
    <div class='margin-fix pa-3'>
      <TextFieldInput label='Site*' placeHolder='Informe o endereço virtual do site'
        v-model='form.site'
        :validationRules='[validationRules.site]'
        @focusout="validate('site')"
        labelWidth="20%" />
    </div>
    <div class='margin-fix d-flex'>
      <label class="custom-label--20"> CEP* </label>
      <MaskInput
        dense
        light
        filled
        placeholder='Informe o CEP'
        v-model='form.zipCode'
        :rules='[validationRules.zipCode]'
        @focusout="validate('zipCode')" :options="CEPMaskOptions"
      />
    </div>
    <div class='margin-fix pa-3'>
      <TextFieldInput label='Vendido e entregue por*'
        placeHolder='Complemente o texto: Vendido e entregue por...'
        v-model='form.soldAndDeliveredBy'
        :validationRules='[validationRules.soldAndDeliveredBy]'
        @focusout="validate('soldAndDeliveredBy')"
        labelWidth="20%"/>
    </div>
    <div class='margin-fix pa-3'>
      <ComboboxInput label='Origem de dados*'
        placeHolder='Selecione ou digite'
        v-model='form.originData'
        :validationRules='[validationRules.originData]'
        labelValue='text'
        itemValue='value'
        :options='OriginOptions'
        @focusout="validate('originData')"
        labelWidth="20%" />
    </div>
    <div class='margin-fix pa-3'>
      <ComboboxInput label='Plataformas de integração*' placeHolder='Selecione ou digite'
        v-model='form.integrationPlatform'
        labelValue='text'
        itemValue='value'
        :options='PlatformOptions'
        :validationRules='[validationRules.integrationPlatform]'
        @focusout="validate('integrationPlatform')"
        labelWidth="20%" />
    </div>
  </FormContent>
</template>

<script>

import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import ComboboxInput from '@/components/template/form/input/ComboboxInput';
import { PlatformOptions } from '../../../static-data/PlatformOptions';
import { OriginOptions } from '../../../static-data/OriginOptions';
import MaskInput from '@/components/template/form/input/MaskInput';

const CEPMaskOptions = {
  numericOnly: true,
  delimiters: ['-'],
  blocks: [5, 3],
};

export default {
  name: 'GeneralInformationForm',
  components: {
    FormContent,
    TextFieldInput,
    ComboboxInput,
    MaskInput,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: 'Informações Gerais',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selected: true,
      PlatformOptions,
      OriginOptions,
    };
  },
  computed: {
    CEPMaskOptions() {
      return CEPMaskOptions;
    },
  },
  methods: {
    validate(field) {
      this.$emit('validateRequired', this.$options.name, field, this.form[field]);
    },
  },
};

</script>
