export const BankCodeOptions = [
  { value: '197', text: '197 - Stone Pagamentos' },
  { value: '748', text: '748 - Banco Cooperativo Sicredi S.A.' },
  { value: '117', text: '117 - Advanced Cc Ltda' },
  { value: '77', text: '77 - Banco Inter' },
  { value: '735', text: '735 - Neon Pagamentos' },
  { value: '340', text: '340 - Superdigital' },
  { value: '290', text: '290 - PagBank' },
  { value: '121', text: '121 - Banco Agibank S.A.' },
  { value: '237', text: '237 - Next' },
  { value: '260', text: '260 - Nubank' },
  { value: '212', text: '212 - Banco Original' },
  { value: '280', text: '280 - Banco Willbank' },
  { value: '172', text: '172 - Albatross Ccv S.A' },
  { value: '323', text: '323 - Mercado Pago' },
  { value: '188', text: '188 - Ativa Investimentos S.A' },
  { value: '380', text: '380 - PicPay' },
  { value: '280', text: '280 - Avista S.A. Crédito, Financiamento e Investimento' },
  { value: '80', text: '80 - B&T Cc Ltda' },
  { value: '654', text: '654 - Banco A.J.Renner S.A.' },
  { value: '246', text: '246 - Banco ABC Brasil S.A.' },
  { value: '75', text: '75 - Banco ABN AMRO S.A' },
  { value: '121', text: '121 - Banco Agibank S.A.' },
  { value: '25', text: '25 - Banco Alfa S.A.' },
  { value: '641', text: '641 - Banco Alvorada S.A.' },
  { value: '65', text: '65 - Banco Andbank (Brasil) S.A.' },
  { value: '213', text: '213 - Banco Arbi S.A.' },
  { value: '96', text: '96 - Banco B3 S.A.' },
  { value: '33', text: '33 - Banco SANTANDER' },
  { value: '318', text: '318 - Banco BMG S.A.' },
  { value: '752', text: '752 - Banco BNP Paribas Brasil S.A.' },
  { value: '107', text: '107 - Banco BOCOM BBM S.A.' },
  { value: '63', text: '63 - Banco Bradescard S.A.' },
  { value: '31', text: '31 - Código Banco Beg S.A.' },
  { value: '122', text: '122 - Banco Bradesco BERJ S.A.' },
  { value: '204', text: '204 - Banco Bradesco Cartões S.A.' },
  { value: '394', text: '394 - Banco Bradesco Financiamentos S.A.' },
  { value: '237', text: '237 - Banco Bradesco S.A.' },
  { value: '218', text: '218 - Banco BS2 S.A.' },
  { value: '208', text: '208 - Banco BTG Pactual S.A.' },
  { value: '336', text: '336 - Banco C6 S.A – C6 Bank' },
  { value: '473', text: '473 - Banco Caixa Geral – Brasil S.A.' },
  { value: '104', text: '104 - Banco Caixa Econômica Federal' },
  { value: '412', text: '412 - Banco Capital S.A.' },
  { value: '40', text: '40 - Banco Cargill S.A.' },
  { value: '368', text: '368 - Banco Carrefour' },
  { value: '266', text: '266 - Banco Cédula S.A.' },
  { value: '739', text: '739 - Banco Cetelem S.A.' },
  { value: '233', text: '233 - Banco Cifra S.A.' },
  { value: '745', text: '745 - Banco Citibank S.A.' },
  { value: '241', text: '241 - Banco Clássico S.A.' },
  { value: '756', text: '756 - Banco Cooperativo do Brasil S.A. – BANCOOB' },
  { value: '748', text: '748 - Banco Cooperativo Sicredi S.A.' },
  { value: '222', text: '222 - Banco Credit Agricole Brasil S.A.' },
  { value: '505', text: '505 - Banco Credit Suisse (Brasil) S.A.' },
  { value: '69', text: '69 - Banco Crefisa S.A.' },
  { value: '3', text: '3 - Banco da Amazônia S.A.' },
  { value: '83', text: '83 - Banco da China Brasil S.A.' },
  { value: '707', text: '707 - Banco Daycoval S.A.' },
  { value: '51', text: '51 - Banco de Desenvolvimento do Espírito Santo S.A.' },
  { value: '300', text: '300 - Banco de La Nacion Argentina' },
  { value: '495', text: '495 - Banco de La Provincia de Buenos Aires' },
  { value: '494', text: '494 - Banco de La Republica Oriental del Uruguay' },
  { value: '335', text: '335 - Banco Digio S.A' },
  { value: '1', text: '1 - Banco do Brasil S.A.' },
  { value: '47', text: '47 - Banco do Estado de Sergipe S.A.' },
  { value: '37', text: '37 - Banco do Estado do Pará S.A.' },
  { value: '41', text: '41 - Banco do Estado do Rio Grande do Sul S.A.' },
  { value: '4', text: '4 - Banco do Nordeste do Brasil S.A.' },
  { value: '196', text: '196 - Banco Fair Corretora de Câmbio S.A' },
  { value: '265', text: '265 - Banco Fator S.A.' },
  { value: '224', text: '224 - Banco Fibra S.A.' },
  { value: '626', text: '626 - Banco Ficsa S.A.' },
  { value: '94', text: '94 - Banco Finaxis S.A.' },
  { value: '612', text: '612 - Banco Guanabara S.A.' },
  { value: '12', text: '12 - Banco Inbursa S.A.' },
  { value: '604', text: '604 - Banco Industrial do Brasil S.A.' },
  { value: '653', text: '653 - Banco Indusval S.A.' },
  { value: '77', text: '77 - Banco Inter S.A.' },
  { value: '249', text: '249 - Banco Investcred Unibanco S.A.' },
  { value: '184', text: '184 - Banco Itaú BBA S.A.' },
  { value: '29', text: '29 - Banco Itaú Consignado S.A.' },
  { value: '479', text: '479 - Banco ItauBank S.A' },
  { value: '341', text: '341 - Banco Itaú' },
  { value: '376', text: '376 - Banco J. P. Morgan S.A.' },
  { value: '422', text: '422 - Banco Safra' },
  { value: '217', text: '217 - Banco John Deere S.A.' },
  { value: '76', text: '76 - Banco KDB S.A.' },
  { value: '757', text: '757 - Banco KEB HANA do Brasil S.A.' },
  { value: '600', text: '600 - Banco Luso Brasileiro S.A.' },
  { value: '243', text: '243 - Banco Máxima S.A.' },
  { value: '720', text: '720 - Banco Maxinvest S.A.' },
  { value: '389', text: '389 - Banco Mercantil do Brasil S.A.' },
  { value: '370', text: '370 - Banco Mizuho do Brasil S.A.' },
  { value: '746', text: '746 - Banco Modal S.A.' },
  { value: '66', text: '66 - Banco Morgan Stanley S.A.' },
  { value: '456', text: '456 - Banco MUFG Brasil S.A.' },
  { value: '7', text: '7 - Banco Nacional de Desenvolvimento Econômico e Social – BNDES' },
];

export default BankCodeOptions;
