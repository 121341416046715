<template>
  <FormContent :selected='selected' class='pa-6'>
    <div class="d-flex flex-column">
        <div class="d-flex mb-15">
          <v-btn class="ml-auto custom-button--light"
                 depressed @click='add()'>
            + Contatos
          </v-btn>
        </div>
        <v-data-table
          :headers="header"
          :items="content"
          class="elevation-1 custom-table"
          :footer-props="{
              'items-length': 100,
              'items-per-page-text': 'Itens por página',
              'items-per-page-options': [10, 20, 50],
              pageText: '{0}-{1} de {2}',
            }"
        >
          <template v-slot:[`item.phone`]="{ item }">
            <!-- <NumberMaskLabel class="color--grey-1" v-model="item.phone" :options="PhoneMaskOptions" filled /> -->
            <div class="color--grey-1">{{formatPhoneNumber(item.phone)}}</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-icon color="primary" @click="editAction(item)" class="mr-3">mdi-pencil</v-icon>
              <v-icon color="primary" @click="showModalDelete(item)" class="mr-3 ">mdi-delete</v-icon>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="pt-10 pb-10">
              Nenhum resultado encontrado
            </div>
          </template>
        </v-data-table>
      </div>

    <v-dialog v-model='showDialog' max-width='90%' persistent>
      <v-card class="dialog-content">
        <v-card-title class="color--grey-0">
          <h4 data-testid='page-title' class=''>
            Cadastro de Contato
          </h4>
          <v-spacer/>
          <v-btn color='#07BEE7' icon dark @click='formCancel'>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-12">
          <v-form ref="contactForm" lazy-validation >
            <AContactDataForm :form="form" :key="formKey"/>
          </v-form>
        </v-card-text>
        <v-card-actions style='padding: 0 30px 30px 30px;'>
          <v-spacer/>
          <v-btn color='#07BEE7' outlined @click.stop='formCancel'>Cancelar</v-btn>
          <v-btn color='#07BEE7' outlined @click.stop='formSave'>Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertModal :showModal="showDeleteModal">
      <template v-slot:header>
        <div class="d-flex flex-column">
          Atenção!
        </div>
      </template>
      <template v-slot:content>
        <div class="d-flex flex-column">
          <div class="mb-3">
            Todas as informações deste contato serão perdidas com esta ação
          </div>
          <div>Deseja mesmo excluir este contato?</div>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn outlined color="primary" class="mr-3" @click="closeModal">NÃO</v-btn>
        <v-btn color="primary" @click="onDeleteContact">SIM</v-btn>
      </template>
    </AlertModal>
  </FormContent>
</template>

<script>

import Vue from 'vue';
import FormContent from '@/components/template/form/FormContent';
import AContactDataForm from '@/views/seller/forms/AContactDataForm';
import AlertModal from '@/components/modal/AlertModal';

export default Vue.extend({
  name: 'AContactForm',
  components: {
    FormContent,
    AContactDataForm,
    // NumberMaskLabel,
    AlertModal,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Contatos',
    },
    content: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    contactItem: {},
    showDeleteModal: false,
    selected: false,
    header: [
      { text: 'Área', align: 'start', value: 'area', sortable: false },
      { text: 'E-mail', value: 'email', sortable: false },
      { text: 'Telefone', value: 'phone', sortable: false },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    decorators: [],
    errors: {
      type: true,
    },
    showDialog: false,
    formKey: null,
    form: { },
  }),
  methods: {
    add() {
      this.editing = false;
      this.changeShowDialog(true);
    },
    formSave() {
      const { contactForm } = this.$refs;
      const validated = contactForm?.validate();
      if (!validated || this.editing) return;

      this.$emit('addContact', this.form);
      this.clearForm();
      this.changeShowDialog(false);
    },
    editAction(item) {
      this.editing = true;
      this.form = { ...item };
      this.changeShowDialog(true);
    },
    formCancel() {
      this.changeShowDialog(false);
      this.clearForm();
    },
    showModalDelete(item) {
      this.showDeleteModal = true;
      this.contactItem = item;
    },
    onDeleteContact() {
      this.$emit('onDeleteContact', this.contactItem);
      this.showDeleteModal = false;
      this.clearForm();
    },
    closeModal() {
      this.showDeleteModal = false;
    },
    changeShowDialog(show) {
      this.showDialog = show;
    },
    clearForm() {
      this.form = {
        area: null,
        email: null,
        phone: null,
      };

      const { contactForm } = this.$refs;
      contactForm.reset();
    },
    formatPhoneNumber(input) {
        const cleaned = input.toString().replace(/\D/g, '');

        const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})$/);
        if (match) {
          return `${match[1]} ${match[2]}-${match[3]}`;
        }
        return cleaned;
    },
  },
});

</script>
